<i18n lang="json">
{
	"ru": {
    "reviews": "отзывов | {n} отзыв | {n} отзыва | {n} отзывов"
	},
	"en": {
    "reviews": "reviews | {n} review | {n} reviews | {n} reviews"
	}
}
</i18n>
<template>
  <span
    v-if="isSmall"
    class="rating-list pad-block"
    :class="{ scale }"
    :data-rating="Math.round(dataRating)"
    itemprop="ratingValue"
    :content="dataRating"
  >
    <template v-if="viewStars">
      <div class="icon-app-star" :class="{ red: isExternalRating }"></div>
      <span
        v-if="isExternalRating"
        class="rating-list__rating"
        :class="{ 'text-bold': textBold }"
      >
        {{ $filters.newRatingExternal(dataRating) }}
      </span>
      <span
        v-else
        class="rating-list__rating"
        :class="{ 'text-bold': textBold }"
      >
        {{ $filters.newRating(dataRating) }}
      </span>
      <span
        v-if="countReviews >= 0 && viewCount"
        :class="{ 'count-white': countWhite }"
        class="rating-list__count"
        itemprop="reviewCount"
        >({{ reviewsText ? t("reviews", countReviews) : countReviews }})
      </span>
    </template>
    <template v-else-if="countReviews >= 0 && viewCount">
      <span
        :class="{ 'count-white': countWhite }"
        class="rating-list__count"
        itemprop="reviewCount"
        >{{ reviewsText ? t("reviews", countReviews) : countReviews }}</span
      >
    </template>
  </span>
  <span
    v-else
    class="rating-list pad-block"
    :data-rating="Math.round(dataRating)"
    itemprop="ratingValue"
    :content="dataRating"
  >
    <template v-if="viewStars">
      <div class="icon-app-star"></div>
      <div class="icon-app-star"></div>
      <div class="icon-app-star"></div>
      <div class="icon-app-star"></div>
      <div class="icon-app-star"></div>
    </template>
    <span
      v-if="countReviews >= 0 && viewCount"
      :class="{ 'count-white': countWhite }"
      class="rating-list__count"
      itemprop="reviewCount"
      >{{ reviewsText ? t("reviews", countReviews) : countReviews }}</span
    >

    <div v-if="isHotel" class="object-hotel">{{ isHotel.title }}</div>
  </span>
</template>
<script>
import { useI18n } from 'vue-i18n';
export default {
  name: "BaseRaiting",
  props: {
    dataRating: {
      type: Number,
      required: true,
    },
    viewCount: {
      type: Boolean,
      required: false,
      default: false,
    },
    viewStars: {
      type: Boolean,
      default: true,
    },
    countReviews: {
      type: Number,
      required: false,
    },
    isHotel: {
      type: Object,
      required: false,
    },
    countWhite: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    scale: {
      type: Boolean,
      default: false,
    },
    reviewsText: {
      type: Boolean,
      default: false,
    },
    isExternalRating: {
      type: Boolean,
      default: false,
    },
    textBold: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
};
</script>

<style lang="scss" scoped>
.rating-list {
  // z-index: 2;
  padding: 0;
  margin: 0;
  list-style: none;
  // position: absolute;
  // top: 10px;
  // left: 8px;
  display: flex;

  &.scale {
    align-items: flex-end;
    div {
      font-size: 22px;
      width: 14px;
      margin-right: 6px;
    }
    .rating-list__rating {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .count {
    &-white {
      color: #fff;
    }
  }

  .object-hotel {
    margin-left: auto;
    display: inline-block;
    font-size: 10px;
    text-transform: uppercase;
    color: #444444;
    border: 1px solid #d8d8d8;
    border-radius: 2px;
    padding: 1px 3px;
  }

  div {
    // Pixel Perfect
    margin-left: -2px;
    margin-right: 2px;

    text-align: center;
    font-size: 16px;
    width: 12px;
    color: #d8d8d8;
  }
  .icon-app-star.red {
    color: #f51449;
  }
  &[data-rating="1"] {
    div:nth-child(1) {
      color: #f51449;
    }
  }
  &[data-rating="2"] {
    div:nth-child(1),
    div:nth-child(2) {
      color: #f51449;
    }
  }
  &[data-rating="3"] {
    div:nth-child(1),
    div:nth-child(2),
    div:nth-child(3) {
      color: #f51449;
    }
  }
  &[data-rating="4"] {
    div:nth-child(1),
    div:nth-child(2),
    div:nth-child(3),
    div:nth-child(4) {
      color: #f51449;
    }
  }
  &[data-rating="5"] {
    div:nth-child(1),
    div:nth-child(2),
    div:nth-child(3),
    div:nth-child(4),
    div:nth-child(5) {
      color: #f51449;
    }
  }

  &__count,
  &__rating {
    font-size: 13px;
    line-height: 16px;
  }

  &__rating {
    padding-left: 6px;
    color: #000;
  }
  &__count {
    padding-left: 3px;
    color: #7f7f7f;
  }
  .text-bold {
    font-weight: bold;
  }
}
</style>
